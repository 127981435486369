.btn-select {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
	text-align: center;
	cursor: pointer;
	color: var(--text-color);
	text-decoration: none;
	border: 1px solid var(--border-color);
	border-radius: 5px;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
		0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075);
	transition: all .3s ease-out;
}

.btn-select span {
	display: block;
	font-size: 20px;
}

.btn-select img {
	display: block;
	max-width: 80px;
}

.btn-select:hover {
	transform: translateY(-6%) scale(1.02);
	border-color: var(--primary-color);
	color: var(--primary-color);
	text-decoration: none;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
		0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
		0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}

@media screen and (max-width: 767px) {
	.btn-select {
		flex-direction: row;
		width: 100%;
	}

	.btn-select span {
		margin-left: 10px;
	}
}
