.provider-logos {
	background-color: white;
	text-align: center;
}

.provider-logos img {
	display: block;
	margin: 0 auto;
	width: 100%;
}
