.thank-you-container img {
	display: block;
	max-height: 200px;
	margin: 0 auto 30px auto;
}

.success {
	color: green;
}

.wizard-step .thank-you-title {
	margin-bottom: 30px;
}

.wizard-step .thank-you-subtitle {
	font-size: 30px;
}
