footer {
	padding: 80px 0;
	background-color: var(--footer-color);
	color: var(--footer-text-color);
}

.footer-links-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.footer-links-container a {
	color: var(--footer-text-color);
	text-decoration: underline;
}

.footer-links-container a:hover {
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	.footer-links-container {
		flex-direction: column;
	}

	.footer-links-container a {
		margin-top: 11px;
	}

	.footer-links-container a:first-child {
		margin-top: 0;
	}
}
