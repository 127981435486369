@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
	font-family: 'Poppins', sans-serif;
}

.app {
	text-align: center;
}

.shadow {
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
		0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
		0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}

.container {
	max-width: 850px;
}

.wizard-step h2 {
	font-size: 30px;
	font-weight: normal;
}

.wizard-step h3 {
	font-size: 40px;
	font-weight: 900;
}

.inputs-container {
	max-width: 40%;
	margin: 30px auto 0 auto;
}

.green {
	color: #118738;
}

.red {
	color: #ff0000;
}

.btn.green {
	background-color: #118738 !important;
}

.show-desktop
	{
		display: block;
	}

.show-mobile
	{
		display: none;
	}

@media screen and (max-width: 767px) {
	.input-container .form-control {
		max-width: 100%;
	}

	.inputs-container {
		max-width: 100%;
	}

	.show-desktop
	{
		display: none;
	}

	.show-mobile
		{
			display: block;
		}
}

.app-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding-left: 20px;
	padding-right: 20px;
	background-color: var(--primary-color);
	color: var(--header-text-color);
	padding-top: 10px;
	padding-bottom: 10px;
}

.app-header .app-logo {
	height: 80px;
	min-width: 200px;
	background: transparent var(--logo) no-repeat center;
	background-size: contain;
	cursor: pointer;
}

.app-header span {
	font-size: 20px;
}

.app-header a {
	color: var(--header-text-color);
	text-decoration: none;
}

.app-header a:hover {
	text-decoration: underline;
}

@media screen and (max-width: 767px) {
	.app-header {
		justify-content: center;
	}

	.app-header .app-logo {
		height: 50px;
	}

	.app-header :not(div.app-logo) {
		display: none;
	}
}

.wizard-step {
	margin: 60px auto;
	max-width: 850px;
	padding: 80px 40px;
	border: 1px solid var(--border-color);
	border-radius: 6px;
	text-align: center;
}

.wizard-step .buttons-container {
	margin-top: 15px;
}

.wizard-step h3 {
	margin: 0;
	font-weight: 900;
}

@media screen and (max-width: 767px) {
	.wizard-step {
		padding: 30px 20px;
		max-width: 90%;
		margin-top: 30px;
	}
}

.container-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 1140px;
	width: 100%;
	text-align: center;
	transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
}

.container-centered .border-spinner {
	margin: 0 auto;
}

@media screen and (min-width: 1200px) {
	.container-centered {
		max-width: 1140px;
	}
}

@media screen and (min-width: 992px) {
	.container-centered {
		max-width: 960px;
	}
}

@media screen and (max-width: 767px) {
	.container-centered {
		max-width: 100%;
	}
}

.spinner .spinner-border {
	margin-top: 50px;
	transition: all .3s ease-out;
}

.call-now-mobile-container {
	padding: 80px 20px;
}

.disclaimer-container {
	margin-top: 100px;
	margin-bottom: 100px;
	text-align: left;
	padding: 20px;
}

.disclaimer-container a {
	color: var(--text-color);
	text-decoration: none;
}

.disclaimer-container a:hover {
	text-decoration: underline;
}

.primary {
	color: var(--primary-color);
}

.wizard-step .get-quote-title {
	margin-bottom: 10px;
	font-weight: 900;
}

.wizard-step .get-quote-subtitle {
	font-size: 24px;
	font-weight: 900;
	margin-bottom: 30px;
}

.wizard-step .lets-get-you-quote-title {
	font-size: 27px;
	font-weight: 900;
	margin-bottom: 10px;
}

.wizard-step .btn.green {
	height: auto !important;
	min-height: 60px;
}

.btn-select {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 200px;
	height: 200px;
	text-align: center;
	cursor: pointer;
	color: var(--text-color);
	text-decoration: none;
	border: 1px solid var(--border-color);
	border-radius: 5px;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
		0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075);
	transition: all .3s ease-out;
}

.btn-select span {
	display: block;
	font-size: 20px;
}

.btn-select img {
	display: block;
	max-width: 80px;
}

.btn-select:hover {
	-webkit-transform: translateY(-6%) scale(1.02);
	        transform: translateY(-6%) scale(1.02);
	border-color: var(--primary-color);
	color: var(--primary-color);
	text-decoration: none;
	box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
		0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
		0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
}

@media screen and (max-width: 767px) {
	.btn-select {
		flex-direction: row;
		width: 100%;
	}

	.btn-select span {
		margin-left: 10px;
	}
}

.makes-buttons-container {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	gap: 30px;
}

.makes-buttons-container .btn-select {
	padding: 20px 0;
	width: 100%;
	height: auto;
}

.credit-score-container .makes-buttons-container {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 767px) {
	.makes-buttons-container {
		grid-template-columns: 1fr;
	}

	.credit-score-container .makes-buttons-container {
		grid-template-columns: 1fr;
	}
}

.yes-no-container {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.yes-no-container .btn-select {
	margin-left: 30px;
}

.yes-no-container .btn-select:first-child {
	margin-left: 0;
}

.how-can-we-help .makes-buttons-container {
	grid-template-columns: 1fr;
}

.makes-buttons-container {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	gap: 30px;
}

.makes-buttons-container .btn-select {
	padding: 20px 0;
	width: 100%;
	height: auto;
}

@media screen and (max-width: 767px) {
	.makes-buttons-container {
		grid-template-columns: 1fr;
	}
}

.makes-buttons-container {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 30px;
	gap: 30px;
}

.makes-buttons-container .btn-select {
	padding: 20px 0;
	width: 100%;
	height: auto;
}

@media screen and (max-width: 767px) {
	.makes-buttons-container {
		grid-template-columns: 1fr;
	}
}



.thank-you-container img {
	display: block;
	max-height: 200px;
	margin: 0 auto 30px auto;
}

.success {
	color: green;
}

.wizard-step .thank-you-title {
	margin-bottom: 30px;
}

.wizard-step .thank-you-subtitle {
	font-size: 30px;
}

.years-buttons-container {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-gap: 30px;
	gap: 30px;
}

.years-buttons-container .btn-select {
	padding: 20px 0;
	width: 100%;
	height: auto;
}

@media screen and (max-width: 767px) {
	.years-buttons-container {
		grid-template-columns: 1fr;
	}
}

.yes-no-container {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.yes-no-container .btn-select {
	margin-left: 30px;
}

.yes-no-container .btn-select:first-child {
	margin-left: 0;
}

@media screen and (max-width: 767px) {
	.yes-no-container {
		flex-direction: column;
	}

	.yes-no-container .btn-select {
		margin-left: 0;
		margin-top: 30px;
		padding: 20px 0;
		width: 100%;
		height: auto;
	}

	.yes-no-container .btn-select:first-child {
		margin-top: 0;
	}
}

.yes-no-container {
	margin-top: 60px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.yes-no-container .btn-select {
	margin-left: 30px;
}

.yes-no-container .btn-select:first-child {
	margin-left: 0;
}

@media screen and (max-width: 767px) {
	.yes-no-container {
		flex-direction: column;
	}

	.yes-no-container .btn-select {
		margin-left: 0;
		margin-top: 30px;
		padding: 20px 0;
		width: 100%;
		height: auto;
	}

	.yes-no-container .btn-select:first-child {
		margin-top: 0;
	}
}

.provider-logos {
	background-color: white;
	text-align: center;
}

.provider-logos img {
	display: block;
	margin: 0 auto;
	width: 100%;
}

.testimonial-container {
	margin: 0 auto;
	margin-bottom: 60px;
	text-align: center;
	max-width: 850px;
	width: 100%;
	padding: 60px 40px;
	border: 1px solid var(--border-color);
	border-radius: 6px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: white;
}

.testimonial-container img {
	max-width: 160px;
	margin: 0 auto;
	margin-right: 30px;
	display: block;
	border-radius: 6px;
}

.testimonial-container .testimonial-content {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.testimonial-container span {
	display: block;
	font-size: 20px;
	font-weight: bold;
}

.testimonial-container span.location {
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 30px;
}

.testimonial-container blockquote {
	text-align: left;
	font-style: italic;
}

.testimonial-container .testimonial-person {
	text-align: right;
}

@media screen and (max-width: 767px) {
	.testimonial-container {
		margin-left: 10px;
		margin-right: 10px;
		flex-direction: column;
		justify-content: center;
	}

	.testimonial-container img {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 30px;
	}

	.testimonial-container .testimonial-content {
		justify-content: center;
		align-items: center;
	}

	.testimonial-container blockquote {
		text-align: center;
	}

	.testimonial-container .testimonial-person {
		text-align: center;
	}
}

.progress-bar-container {
	background-color: var(--progress-bar-container-color);
	color: var(--progress-bar-text-color);
	padding: 20px 0;
}

.progress-bar-container h3 {
	font-size: 26px;
}

.progress-bar-container > .container {
	padding: 0;
}

.progress-bar-container .progress-bar {
	position: relative;
	border: 1px solid white;
	border-radius: 5px;
	overflow: hidden;
	background-color: transparent;
	padding: 30px 0 0 0;
	/* margin: 20px 0; */
}

.progress-bar-container .progress-bar .progress-bar-track {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	background-color: var(--progress-bar-color);
	transition: all .4s ease-out;
}

.progress-bar-container .progress-bar span {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	-webkit-transform: translateX(-50%) translateY(-50%);
	color: white;
}

@media screen and (max-width: 767px) {
	.progress-bar-container {
		padding: 30px 20px;
	}

	.progress-bar-container h3 {
		font-size: 16px;
	}
}

.provider-logos {
	background-color: white;
	text-align: center;
}

.provider-logos img {
	display: block;
	margin: 0 auto;
	width: 100%;
}

footer {
	padding: 80px 0;
	background-color: var(--footer-color);
	color: var(--footer-text-color);
}

.footer-links-container {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.footer-links-container a {
	color: var(--footer-text-color);
	text-decoration: underline;
}

.footer-links-container a:hover {
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	.footer-links-container {
		flex-direction: column;
	}

	.footer-links-container a {
		margin-top: 11px;
	}

	.footer-links-container a:first-child {
		margin-top: 0;
	}
}

.legal-disclaimer {
	text-align: left;
}

.legal-disclaimer h3 {
	margin-bottom: 30px;
}

.legal-disclaimer p {
	margin-bottom: 10px;
}

.legal-disclaimer img {
	max-width: 50%;
}

