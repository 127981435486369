.makes-buttons-container {
	margin-top: 30px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 30px;
}

.makes-buttons-container .btn-select {
	padding: 20px 0;
	width: 100%;
	height: auto;
}

.credit-score-container .makes-buttons-container {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 767px) {
	.makes-buttons-container {
		grid-template-columns: 1fr;
	}

	.credit-score-container .makes-buttons-container {
		grid-template-columns: 1fr;
	}
}
